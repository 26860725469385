import React from "react";
import { caseFileActions } from "../../../constants/caseFiles";
import documentService from "../../../services/documentService";
import reactQueryClient from "../../../reactQueryClient";
import queryKeys from "../../../constants/queryKeys";
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
}));

export default function FileDeleteDialog({
    updateTreeData,
    treeData,
    setTreeData,
    fileDeleteDialogOpen,
    setFileDeleteDialogOpen,
    fileToRemove,
    newParent,
    caseId,
}) {
    const classes = useStyles();

    const removeNode = () => {
        const updatedTreeData = updateTreeData(treeData, fileToRemove, newParent, caseFileActions.REMOVE);
        setTreeData(updatedTreeData);

        try {
            documentService.removeCaseFile(fileToRemove.id);
        }
        catch {
            reactQueryClient.invalidateQueries([queryKeys.caseFilesTree, caseId]);
        }
    };

    const removeFile = () => {
        removeNode();
        setFileDeleteDialogOpen(false);
    };

    return (
        <Dialog
            open={fileDeleteDialogOpen}
            onClose={() => setFileDeleteDialogOpen(false)}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        className={classes.diaTitle}
                    >
                        Confirm removal of File
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm that you want to remove this file?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setFileDeleteDialogOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => removeFile()}
                >
                    Remove File
                </Button>
            </DialogActions>
        </Dialog>
    )
}