import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes } from "./index";
import FlexSidebar from "../components/FlexSidebar";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";
import Page404 from "../mui/auth/Page404";
import { Box } from "@material-ui/core";
import { useAuth } from "../contexts/authContext";
import routeUtils from "../utils/routeUtils";
import CaseHistoryExport from "../pages/CaseHistoryExport";
import useFeatureFlag from "../hooks/useFeatureFlag";

const childRoutes = (Layout, routes, hasRole) =>
    routeUtils.filterAllowedRoutes(routes, hasRole).map(({ children, path, viewtype, component: Component, requiredRoles }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, viewtype, component: Component, requiredRoles }, index) =>
                routeUtils.isRouteAllowed({ requiredRoles }, hasRole) ? (
                    <Route
                        key={index}
                        path={path}
                        exact
                        render={(props) => (
                            <Layout>
                                <Component viewtype={viewtype} {...props} />
                            </Layout>
                        )}
                    />
                ) : (
                    <Redirect key={index} from={path} to="/dashboard" exact />
                )
            )
        ) : routeUtils.isRouteAllowed({ requiredRoles, path }, hasRole) ? (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                    <Layout>
                        <Component viewtype={viewtype} {...props} />
                    </Layout>
                )}
            />
        ) : (
            <Redirect key={index} from={path} to="/dashboard" exact />
        )
    );

const Routes = () => {
    const { hasRole, user } = useAuth();
    const { data: isAIEnabled } = useFeatureFlag("AITab", true);   

    useEffect(() => {
        let roles = user.details.roles;
        if(isAIEnabled)
            roles.push("ai");        

        window.InlineManual("boot", { name: user.details.firstName, uid: user.details.email, roles }, { language: 'en' });
        window.InlineManual("update");

        return () => {
            window.InlineManual("shutdown");
        }
    }, [user, isAIEnabled]);   


    return (
        <Box display="flex">
            <Box flexGrow={1}>
                <Switch>
                    {childRoutes(DashboardLayout, dashboardRoutes, hasRole)}
                    <Route
                        exact
                        path="/casehistoryexport/:guid"
                        render={(props) => (
                            <AuthLayout>
                                <CaseHistoryExport {...props} />
                            </AuthLayout>
                        )}
                    />
                    <Route
                        render={() => (
                            <AuthLayout>
                                <Page404 />
                            </AuthLayout>
                        )}
                    />
                </Switch>
            </Box>
            <FlexSidebar />
        </Box>
    );
};

export default Routes;
